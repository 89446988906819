import { HomePage, DisplayDataPage, ProfilePage ,DossiersDataPage,DossiersJoursDataPage,DossiersUrgentsDataPage} from './pages';

export default [
  {
    path: '/display-data',
    component: DisplayDataPage
  },
  {
    path: '/dossiers',
    component: DossiersDataPage
  },
  {
    path: '/dossiers-jours',
    component: DossiersJoursDataPage
  },
  {
    path: '/dossiers-urgents',
    component: DossiersUrgentsDataPage
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/home',
    component: HomePage
  }
  ];
