export const navigation = [
  {
    text: 'Accceuil',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Tableau de bord',
    icon: 'folder',
    items: [
      {
        text: 'Dossiers',
        path: '/dossiers'
      },
      {
        text: 'Dossiers du jours',
        path: '/dossiers-jours'
      },
      {
        text: 'Dosssiers urgents',
        path: '/dossiers-urgents'
      },
      {
        text: 'Profile',
        path: '/profile'
      },
      {
        text: 'Display Data',
        path: '/display-data'
      }
    ]
  }
  ];
